import { Module } from 'vuex';

import service from '@/store/services/chatAIConversation-service';
import {ChatAIResponse} from "@/types";
import {ChatInterfaceInfo} from "@/store/apiPayloadsFiles/payloadsInfo";

interface ChatAIConversationState {
    question?: string;
    answer?: string;
    messages: [];
}

const state: ChatAIConversationState = {
    messages: [],
}

const mutations = {
    SET_AI_CHAT_CONVERSATION(state: ChatAIConversationState, response: ChatAIResponse) {
        if (response.question) state.question = response.question;
        if (response.answer) state.answer = response.answer;
        state.messages = response.messages;
    }
}

const actions = {
    individualChatConversation({ commit, dispatch }: any, params: any) {
        return service.individualAIChatConversation(params)
            .then((response: any) => {
                commit('SET_AI_CHAT_CONVERSATION', response);
            });
    },
    expertChatConversation({ commit, dispatch }: any, params: any) {
        return service.expertAIChatConversation(params)
            .then((response: any) => {
                commit('SET_AI_CHAT_CONVERSATION', response);
            });
    }
}

const getters = {
    chatAIconversation: (state: ChatAIConversationState) => state,
}

const chatAIConversationModule: Module<ChatAIConversationState, any> = {
    state,
    mutations,
    actions,
    getters
}

export default chatAIConversationModule;